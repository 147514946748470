'use strict';
import BaseNavClass from '../BaseNavClass';

/**
 * The Menu based Navigation Class
 *
 * @export
 * @class MenuNavClass
 * @extends {BaseNavClass}
 */
export default class MenuNavClass extends BaseNavClass {
    constructor(...args) {
        super(...args);
    }
}
