'use strict';

export default class GlobalNavigationController {
    static get $inject() {
        return ['_', '$scope'];
    }

    constructor(_, $scope) {
        this._ = _;
        this.scope = $scope;
        this.scope.data = {};
        this.scope.hooks = {};
    }
}
